
import { Platform } from '@telegram-apps/sdk-react';
import { default as WebApp, default as WebAppUser } from "@twa-dev/sdk";
import eruda from "eruda";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, Route, BrowserRouter as Router, Routes } from "react-router-dom";
import './App.css';
import './AppPredict.css';
import { PredictLayout } from './Components/Predict/layout/layout';
import GameContext from './Context/GameContext';
import './darkTheme.css';
import Layout from "./Layout";
import './lightTheme.css';
import Index from "./Pages";
import Boosts from "./Pages/Boosts";
import Coins from "./Pages/coins";
import Earns from "./Pages/earns";
import Families from "./Pages/families";
import FamilyGame from './Pages/familyGame/family-game';
import Improvements from "./Pages/improvements";
import Leaderboard from "./Pages/leaderboard";
import { FundsAllocationPage } from './Pages/Predict/fundsAllocation/fundsAllocation';
import { MarketplacePage } from './Pages/Predict/marketplace/marketplace';
import { MarketplaceNftsBlock } from './Pages/Predict/marketplace/nftsBlock';
import { MarketplacePixiesBlock } from './Pages/Predict/marketplace/pixiesBlock';
import { MarketplaceSkinsUpgradeBlock } from './Pages/Predict/marketplace/skinsUpgradeBlock';
import { ContestsBlock } from './Pages/Predict/pixieFamilies/contestsBlock';
import { FamilyBlock } from './Pages/Predict/pixieFamilies/familyBlock';
import { LeaderboardBlock } from './Pages/Predict/pixieFamilies/leaderboardBlock';
import { PixieFamiliesPage } from './Pages/Predict/pixieFamilies/pixieFamilies';
import { PredictionEditPage } from "./Pages/Predict/predictions/edit/predictionEditPage";
import { PredictionPage } from "./Pages/Predict/predictions/predictionPage";
import PredictionsPage from "./Pages/Predict/predictions/predictionsPage";
import { ProfileBalanceBlock } from './Pages/Predict/profile/balance';
import { ProfileBalanceHistoryBlock } from './Pages/Predict/profile/balanceHistory';
import { ProfileGameHistoryBlock } from "./Pages/Predict/profile/gameHistory";
import { ProfileInfoBlock } from './Pages/Predict/profile/info';
import { ProfileNftsBlock } from './Pages/Predict/profile/nfts';
import { ProfilePage } from './Pages/Predict/profile/profile';
import { ProfileReferralBlock } from './Pages/Predict/profile/referral';
import { ProfileStatsBlock } from './Pages/Predict/profile/stats';
import Referrals from "./Pages/referrals";
import Skins from "./Pages/skins";
import Loader from './Components/Loader';
import PredictLeaderBoard from './Pages/Predict/predictions/leadboard/predictLeadboard';
import { ProfileNftBlock } from './Pages/Predict/profile/nft';
import ProfileHistoryBlock from './Pages/Predict/profile/history';
import ActivePredictionsPage from './Pages/Predict/predictions/activePredictions/activePredictionsPage';

export default function App() {
  const [platform, setPlatform] = useState<Platform | null>(null);
  const [isTelegramWebApp, setIsTelegramWebApp] = useState<boolean | null>(null);
  const { userId } = useContext(GameContext)

  useEffect(() => {
    WebApp.ready();
    WebApp.expand();
    // WebApp.EnableClosingConfirmation();
    // EnableClosingConfirmation

    if (WebAppUser.platform) {
      setPlatform(WebAppUser.platform);
    }
    else {
      setPlatform('unknown');
    }

    if ([875591451, 939941303, 215159309].includes(userId ?? 0)) {
      eruda.init();
    }
  }, []);

  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (platform == null) return;

    if (WebAppUser.initDataUnsafe && WebAppUser.initDataUnsafe.user) {
      if (WebAppUser.initDataUnsafe.user.language_code === 'ru') {
        i18n.changeLanguage("ru");
      } else if (WebAppUser.initDataUnsafe.user.language_code === 'uk') {
        i18n.changeLanguage("uk");
      } else if (WebAppUser.initDataUnsafe.user.language_code === 'fa') {
        i18n.changeLanguage("fa");
      } else if (WebAppUser.initDataUnsafe.user.language_code === 'fr') {
        i18n.changeLanguage("fr");
      } else {
        i18n.changeLanguage("en");
      }

      if (WebAppUser.initDataUnsafe.user.id === 5208434230) {
        i18n.changeLanguage("en");
      }
      if (WebAppUser.initDataUnsafe.user.id === 393370256) {
        i18n.changeLanguage("uk");
      }
    } else {
      i18n.changeLanguage("ru");
    }

    if (platform === 'ios' || platform === 'android') {
      setIsTelegramWebApp(true);
    } else {
      setIsTelegramWebApp(false);
      // setIsTelegramWebApp(true);
    }
  }, [platform]);

  return (
    <>
      {
        isTelegramWebApp == null ? <Loader />
          : isTelegramWebApp == true ?
            <Router>
              <Routes>
                <Route path="/" element={<Layout />}>
                  <Route path="/:id" element={<Index />} />
                  <Route path="/leaderboard" element={<Leaderboard />} />
                  <Route path="/referrals" element={<Referrals />} />
                  <Route path="/improve" element={<Improvements />} />
                  <Route path="/boosts" element={<Boosts />} />
                  <Route path="/earns" element={<Earns />} />
                  <Route path="/coins" element={<Coins />} />
                  <Route path="/skins" element={<Skins />} />
                  <Route path="/families" element={<Families />} />
                </Route>
                <Route path="/family-game" element={<FamilyGame />} />

                <Route path="/predict" element={<PredictLayout />} >
                  <Route path="/predict/pixie-families" element={<PixieFamiliesPage />} >
                    <Route path="/predict/pixie-families/my-family" element={<FamilyBlock />} />
                    <Route path="/predict/pixie-families/laederboard" element={<LeaderboardBlock />} />
                    <Route path="/predict/pixie-families/contests" element={<ContestsBlock />} />
                  </Route>

                  <Route path="/predict/predictions" element={<PredictionsPage />} />
                  <Route path="/predict/laederboard" element={<PredictLeaderBoard />} />
                  <Route path="/predict/predictions/:id" element={<PredictionPage />} />
                  <Route path="/predict/predictions/create" element={<PredictionEditPage />} />
                  <Route path="/predict/predictions/edit/:id" element={<PredictionEditPage />} />
                  <Route path="/predict/funds-allocation" element={<FundsAllocationPage />} />

                  <Route path="/predict/marketplace" element={<MarketplacePage />} >
                    <Route path="/predict/marketplace/nfts" element={<MarketplaceNftsBlock />} />
                    <Route path="/predict/marketplace/pixies" element={<MarketplacePixiesBlock />} />
                    <Route path="/predict/marketplace/skins-upgrade" element={<MarketplaceSkinsUpgradeBlock />} />
                  </Route>

                  <Route path="/predict/profile" element={<ProfilePage />} >
                    <Route path="/predict/profile/info" element={<ProfileInfoBlock />} />
                    <Route path="/predict/profile/balance" element={<ProfileBalanceBlock />} />
                    <Route path="/predict/profile/history" element={<ProfileHistoryBlock />} />
                    <Route path="/predict/profile/balance-history" element={<ProfileBalanceHistoryBlock />} />
                    <Route path="/predict/profile/game-history" element={<ProfileGameHistoryBlock />} />
                    <Route path="/predict/profile/my-stats" element={<ProfileStatsBlock />} />
                    <Route path="/predict/profile/my-nfts" element={<ProfileNftsBlock />} />
                    <Route path="/predict/profile/my-nfts/:id" element={<ProfileNftBlock />} />
                    <Route path="/predict/profile/referal" element={<ProfileReferralBlock />} />
                  </Route>
                </Route>

              </Routes>
            </Router>
            :
            <Router>
              <Routes>
                <Route path="/predict" element={<PredictLayout />} >
                  <Route path="/predict/pixie-families" element={<PixieFamiliesPage />} >
                    <Route path="/predict/pixie-families/my-family" element={<FamilyBlock />} />
                    <Route path="/predict/pixie-families/leaderboard" element={<LeaderboardBlock />} />
                    <Route path="/predict/pixie-families/contests" element={<ContestsBlock />} />
                  </Route>

                  <Route path="/predict/predictions" element={<PredictionsPage />} />
                  <Route path="/predict/predictions/:id" element={<PredictionPage />} />
                  <Route path="/predict/laederboard" element={<PredictLeaderBoard />} />
                  <Route path="/predict/predictions/create" element={<PredictionEditPage />} />
                  <Route path="/predict/predictions/edit/:id" element={<PredictionEditPage />} />
                  <Route path="/predict/funds-allocation" element={<FundsAllocationPage />} />

                  <Route path="/predict/marketplace" element={<MarketplacePage />} >
                    <Route path="/predict/marketplace/nfts" element={<MarketplaceNftsBlock />} />
                    <Route path="/predict/marketplace/pixies" element={<MarketplacePixiesBlock />} />
                    <Route path="/predict/marketplace/skins-upgrade" element={<MarketplaceSkinsUpgradeBlock />} />
                  </Route>

                  <Route path="/predict/profile" element={<ProfilePage />} >
                    <Route path="/predict/profile/info" element={<ProfileInfoBlock />} />
                    <Route path="/predict/profile/balance" element={<ProfileBalanceBlock />} />
                    <Route path="/predict/profile/history" element={<ProfileHistoryBlock />} />
                    <Route path="/predict/profile/balance-history" element={<ProfileBalanceHistoryBlock />} />
                    <Route path="/predict/profile/game-history" element={<ProfileGameHistoryBlock />} />
                    <Route path="/predict/profile/my-stats" element={<ProfileStatsBlock />} />
                    <Route path="/predict/profile/my-nfts" element={<ProfileNftsBlock />} />
                    <Route path="/predict/profile/my-nfts/:id" element={<ProfileNftBlock />} />
                    <Route path="/predict/profile/referal" element={<ProfileReferralBlock />} />
                  </Route>
                </Route>
                <Route path="/:id" element={<Navigate to="/predict/predictions" />} />
                <Route path="/leaderboard" element={<MobileOnlyMessage platform={platform} />} />
                <Route path="/referrals" element={<MobileOnlyMessage platform={platform} />} />
                <Route path="/improve" element={<MobileOnlyMessage platform={platform} />} />
                <Route path="/boosts" element={<MobileOnlyMessage platform={platform} />} />
                <Route path="/earns" element={<MobileOnlyMessage platform={platform} />} />
                <Route path="/coins" element={<MobileOnlyMessage platform={platform} />} />
                <Route path="/skins" element={<MobileOnlyMessage platform={platform} />} />
                <Route path="/families" element={<MobileOnlyMessage platform={platform} />} />
                <Route path="/family-game" element={<MobileOnlyMessage platform={platform} />} />
                <Route path="*" element={<MobileOnlyMessage platform={platform} />} />
              </Routes>
            </Router>
      }
    </>
  );
}

function MobileOnlyMessage({ platform }: { platform: string | null }) {
  const { t } = useTranslation();

  return (
    <div className="just-webapp">
      <span className="just-webapp-text">
        {t('Play on your mobile!')}
      </span>

      <a href="https://t.me/pixie_project_bot" className="just-webapp-link">*{t('click')}*</a>

      {
        platform != null &&
        <span className="platform-name">{platform}</span>
      }
    </div>
  );
}