import { DateTime } from "luxon";
import { PredictBlank } from "../../Pages/Predict/predictions/edit/predictionEditPage";
import { BetType } from "../../Pages/Predict/predictions/fast/setPositionModal/setPositionModal";
import { CustomGameProcess, mapToCustomGameProcess } from "./customGameProcess";
import { Game, mapToGame } from "./game";
import { GameHistory, mapToGameHistory } from "./gameHistory";
import { GameProcess, mapToGameProcess } from "./gameProcess";
import { Fund, mapToFund } from "./fund";

export class PredictProvider {
    public static async create(predictBlank: PredictBlank, token: string) {
        const formData = new FormData();
        predictBlank.name != null && formData.append("name", predictBlank.name);
        predictBlank.description != null && formData.append("description", predictBlank.description);
        predictBlank.button_name_up != null && formData.append("button_name_up", predictBlank.button_name_up);
        predictBlank.button_name_down != null && formData.append("button_name_down", predictBlank.button_name_down);
        predictBlank.start_at != null && formData.append("start_at", DateTime.fromJSDate(predictBlank.start_at).toFormat('yyyy-MM-dd HH:mm:ss'));
        predictBlank.end_at != null && formData.append("end_at", DateTime.fromJSDate(predictBlank.end_at).toFormat('yyyy-MM-dd HH:mm:ss'));
        predictBlank.category_id != null && formData.append("category_id", predictBlank.category_id.toString());
        predictBlank.image != null && formData.append("image", predictBlank.image);

        const response = await fetch(`${process.env.REACT_APP_API_URL}/predicts/v1/game/create`, {
            method: "POST",
            headers: {
                'x-api-key': token
            },
            body: formData
        })
            .then(response => response.json())
            .then(response => response)

        return response;
    }

    public static async getGame(game_id: number, token: string): Promise<{ game: Game, datetime_now: Date }> {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/predicts/v1/game/get/${game_id}`, {
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'x-api-key': token
            },
        })
            .then(response => response.json())
            .then(response => response)

        return { game: mapToGame(response.game), datetime_now: new Date(response.datetime_now) };
    }

    public static async getGames(categoryId: number, token: string): Promise<Game[]> {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/predicts/v1/game/list/category/${categoryId}`, {
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'x-api-key': token
            },
        })
            .then(response => response.json())
            .then(response => response)

        return (response.games as any[]).map(mapToGame);
    }

    public static async getGameProcess(gameId: number, token: string): Promise<{ gamesProcess: GameProcess[], datetime_now: Date }> {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/predicts/v2/game/processes/${gameId}`, {
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'x-api-key': token
            },
        })
            .then(response => response.json())
            .then(response => response)

        return { gamesProcess: (response.games_process as any[]).map(mapToGameProcess), datetime_now: new Date(response.datetime_now) };
    }


    public static async getCustomGameProcess(gameId: number, token: string): Promise<CustomGameProcess> {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/predicts/v2/game/processes/${gameId}`, {
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'x-api-key': token
            },
        })
            .then(response => response.json())
            .then(response => response)

        return mapToCustomGameProcess(response);
    }

    public static async getGamesProcess(token: string): Promise<{ gamesProcess: GameProcess[], dateTimeUtcNow: Date }> {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/predicts/v1/game/feed`, {
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'x-api-key': token
            },
        })
            .then(response => response.json())
            .then(response => response)

        return {
            gamesProcess: (response.processes as any[]).map(mapToGameProcess),
            dateTimeUtcNow: new Date(response.datetime_now)
        }
    }

    public static async bet(bet_type: BetType, game_process_id: number, price: number, token: string): Promise<any> {
        const data = {
            "bet_type": bet_type,
            "game_process_id": game_process_id,
            "price": price
        };

        const response = await fetch(`${process.env.REACT_APP_API_URL}/predicts/v1/game/bet`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'x-api-key': token
            },
            body: JSON.stringify(data)
        })
            .then(response => response.json())
            .then(response => response)

        return response;
    }

    public static async getGameGraphHistory(gameId: number, token: string): Promise<GameHistory[]> {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/predicts/v1/game/graph/history/${gameId}`, {
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'x-api-key': token
            },
        })
            .then(response => response.json())
            .then(response => response)

        return (response.data as any[]).map(mapToGameHistory);
    }

    public static async getBalance(token: string): Promise<number> {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/predicts/v1/me/balance`, {
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'x-api-key': token
            },
        })
            .then(response => response.json())
            .then(response => response)

        return response.balance;
    }

    public static async getFunds(token: string): Promise<Fund[]> {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/predicts/v1/funds/get`, {
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'x-api-key': token
            },
        })
            .then(response => response.json())
            .then(response => response)

        return (response.funds as any[]).map(mapToFund);
    }
}